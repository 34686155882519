import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import transfermovilImage from "assets/images/logos/transfermovil.jpg";
import enzonaImage from "assets/images/logos/enzona.jpg";
import { fieldsSyncStep } from "consts/sign-up/fieldsConfig";
import { usePasswordVisibility } from "hooks/sign-up/formUtils";
import CustomTextField from "components/MDInput/CustomTextField";

const SyncStep = ({ formik }) => {
    const { showPasswords, toggleShowPassword } = usePasswordVisibility([
        "syncTransfermovilPassword",
        "syncEnzonaPassword",
    ]);

    return (
        <Box>
            {fieldsSyncStep.map((field, index) => (
                <React.Fragment key={index}>
                    {field.id.includes("Email") && (
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                component="img"
                                src={
                                    field.id.includes("Transfermovil")
                                        ? transfermovilImage
                                        : enzonaImage
                                }
                                sx={{
                                    width: 50,
                                    height: 50,
                                    mt: field.id.includes("Transfermovil") ? 0 : 5,
                                }}
                            />
                        </Box>
                    )}

                    <CustomTextField
                        id={field.id}
                        label={field.label}
                        formik={formik}
                        type={field.type}
                        showPassword={field.type === "password" && showPasswords[field.id]}
                        toggleShowPassword={
                            field.type === "password" ? () => toggleShowPassword(field.id) : null
                        }
                        adornment={field.type === "password"}
                    />
                </React.Fragment>
            ))}
        </Box>
    );
};

SyncStep.propTypes = {
    formik: PropTypes.shape({
        values: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        touched: PropTypes.object.isRequired,
        handleChange: PropTypes.func.isRequired,
        setFieldValue: PropTypes.func.isRequired,
    }).isRequired,
};

export default SyncStep;
