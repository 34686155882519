import React from "react";
import PropTypes from "prop-types";
import { Step, StepLabel, Stepper } from "@mui/material";
import MDBox from "components/MDBox";

const StepperPayment = ({ formik, step, paymentMethods }) => {
    const selectedPaymentMethods = paymentMethods.filter((method) =>
        formik.values.selectedPayment.includes(method.id)
    );

    return (
        <>
            <MDBox display="flex" justifyContent="center" mb={2} mt={-2}>
                {selectedPaymentMethods.map((method) => (
                    <MDBox
                        key={method.id}
                        component="img"
                        src={method.img}
                        alt={method.label}
                        sx={{
                            width: 40,
                            height: 40,
                            p: 0.5,
                            mx: 2,
                            borderRadius: 2,
                            boxShadow: 2,
                        }}
                    />
                ))}
            </MDBox>
            <Stepper
                activeStep={step - 2}
                alternativeLabel
                sx={{
                    "&.MuiStepper-root": {
                        bgcolor: "#fff",
                        mb: 3,
                    },
                    "& .MuiStepIcon-root": {
                        width: 40,
                        height: 40,
                        stroke: "none",
                        color: "#000",
                        fill: "#318AEC",
                        boxShadow: 3,
                        "&.Mui-active, &.Mui-completed": {
                            fill: "#318AEC",
                            stroke: "none",
                            border: "2px solid #fff",
                            boxShadow: 0,
                        },
                        "& .MuiStepIcon-text": {
                            fontSize: "1rem",
                            color: "#fff",
                            bgcolor: "#318AEC",
                        },
                    },
                    "& .MuiStepConnector-line": {
                        mt: 1.5,
                    },
                }}
            >
                {[1, 2, 3, 4].map((label) => (
                    <Step key={label}>
                        <StepLabel />
                    </Step>
                ))}
            </Stepper>
        </>
    );
};

// Define prop types
StepperPayment.propTypes = {
    formik: PropTypes.shape({
        values: PropTypes.object.isRequired,
    }).isRequired,
    step: PropTypes.number.isRequired,
    paymentMethods: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            img: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default StepperPayment;
