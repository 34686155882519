import _ from "i18n/translate";

export const stepMessages = {
    1: _("Enter your email address and password to register"),
    2: _("General Information"),
    3: _("Business Information"),
    4: _("Account Information"),
    5: _("Payment Plan"),
};

export const fieldsFirstStep = [
    { id: "email", label: _("Email Address"), type: "email" },
    { id: "password", label: _("Password"), type: "password" },
    {
        id: "confirmPassword",
        label: _("Confirm Password"),
        type: "password",
    },
];

export const fieldsSecondStep = [
    { id: "fullName", label: _("Full Name / Represented by"), width: "100%" },
    {
        id: "position",
        label: _("In the capacity of (Position)"),
        visibleWithPayments: ["fincimex"],
        width: "100%",
    },
    { id: "phoneNumber", label: _("Phone (+53)"), width: "45%" },
    {
        id: "userType",
        label: _("User Type"),
        width: "45%",
        select: true,
        visibleWithPayments: ["transfermovil"],
    },
    { id: "idCard", label: _("Identity Card (11 digits)"), width: "45%" },
    { id: "NIT", label: _("Tax Identification Number (NIT)"), width: "45%" },
    {
        id: "businessEmail",
        label: _("Business Email"),
        visibleWithPayments: ["transfermovil"],
        width: "45%",
    },
    {
        id: "contractEmail",
        label: _("Email"),
        visibleWithPayments: ["fincimex"],
        width: "45%",
    },
    {
        id: "officePhone",
        label: _("Office Phone"),
        visibleWithPayments: ["transfermovil"],
        width: "45%",
    },
    {
        id: "transfermovilPassword",
        label: _("Password"),
        visibleWithPayments: ["transfermovil"],
        type: "password",
        width: "45%",
    },
    {
        id: "confirmTransfermovilPassword",
        label: _("Confirm Password"),
        visibleWithPayments: ["transfermovil"],
        type: "password",
        width: "45%",
    },
    {
        id: "fincimexPassword",
        label: _("Password"),
        visibleWithPayments: ["fincimex"],
        type: "password",
        width: "45%",
    },
    {
        id: "confirmFincimexPassword",
        label: _("Confirm Password"),
        visibleWithPayments: ["fincimex"],
        type: "password",
        width: "45%",
    },
];

export const fieldsThirdStep = [
    { id: "personalEmail", label: _("Personal Email"), width: "45%" },
    { id: "homePhone", label: _("Home Phone"), width: "45%" },
    { id: "birthDate", label: _("Birth Date"), type: "date", width: "45%" },
    { id: "gender", label: _("Gender"), width: "45%", select: true },
    { id: "businessName", label: _("Entity/Business Name"), width: "100%" },
    {
        id: "businessCodeType",
        label: _("Entity Code Type"),
        width: "45%",
        select: true,
        visibleWithPayments: ["enzona"],
    },
    {
        id: "businessCode",
        label: _("Entity Code"),
        width: "45%",
        visibleWithPayments: ["enzona"],
    },
    {
        id: "nationality",
        label: _("Nationality"),
        width: "45%",
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "documentType",
        label: _("Document or Deed Type"),
        width: "45%",
        select: true,
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "documentNumber",
        label: _("Document Number"),
        width: "45%",
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "documentDate",
        label: _("Document or Deed Date"),
        width: "45%",
        type: "date",
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "issuingPerson",
        label: _("Person Issuing the Document or Deed"),
        width: "100%",
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "registryNumber",
        label: _("Registry Book Number"),
        width: "45%",
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "folio",
        label: _("Folio"),
        width: "45%",
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "page",
        label: _("Page"),
        width: "45%",
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "section",
        label: _("Section"),
        width: "45%",
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "inscription",
        label: _("Inscription"),
        width: "45%",
        visibleWithPayments: ["fincimex"],
    },
    { id: "legalAddress", label: _("Legal Address"), width: "100%" },
    { id: "province", label: _("Province"), width: "45%", select: true },
    { id: "municipality", label: _("Municipality"), width: "45%", select: true },
    { id: "city", label: _("City"), width: "45%" },
    { id: "postalCode", label: _("Postal Code"), width: "45%" },

    {
        id: "fax",
        label: _("Fax"),
        width: "45%",
        select: false,
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "ONECode",
        label: _("ONE Code of the Entity"),
        width: "45%",
        select: false,
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "contractId",
        label: _("Contract ID"),
        width: "45%",
        visibleWithPayments: ["transfermovil"],
    },
    {
        id: "businessType",
        label: _("Business Type"),
        width: "45%",
        select: true,
        visibleWithPayments: ["transfermovil"],
    },
    {
        id: "createdAt",
        label: _("Created At"),
        type: "date",
        width: "45%",
        visibleWithPayments: ["transfermovil"],
    },
    {
        id: "certifiedCopyOf",
        label: _("Certified Copy of"),
        width: "45%",
        select: false,
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "issuedBy",
        label: _("Issued By"),
        width: "45%",
        select: false,
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "createdIn",
        label: _("Created In"),
        width: "45%",
        select: false,
        type: "date",
        visibleWithPayments: ["fincimex"],
    },
];

export const fieldsFourthStep1 = [
    {
        id: "bank",
        label: _("Bank"),
        select: true,
    },
    {
        id: "accountType",
        label: _("Account Type / Account Currency"),
        select: true,
    },
    {
        id: "accountName",
        label: _("Account Name"),
        type: "text",
        visibleWithPayments: ["transfermovil"],
    },
    { id: "accountNumber", label: _("Account Number"), type: "text" },
];

export const fieldsFourthStep2 = [
    {
        id: "bank2",
        label: _("Bank"),
        width: "100%",
        select: true,
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "accountType2",
        label: _("Account Type / Currency"),
        width: "100%",
        select: true,
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "accountNumber2",
        label: _("Account Number"),
        width: "100%",
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "bankingLicenseNumber",
        label: _("Banking License Number"),
        width: "45%",
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "bankingLicenseDate",
        label: _("Banking License Date"),
        width: "45%",
        type: "date",
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "commercialRegistrationLicenseNumber",
        label: _("Commercial Registration License Number"),
        width: "45%",
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "commercialRegistrationDate",
        label: _("Commercial Registration Date"),
        width: "45%",
        type: "date",
        visibleWithPayments: ["fincimex"],
    },
];

export const termsFourthStep = [
    {
        id: "transfermovilTerms",
        label: _("the terms and conditions of Transfermovil"),
        visibleWithPayments: ["transfermovil"],
    },
    {
        id: "fincimexTerms",
        label: _("the terms and conditions of Fincimex"),
        visibleWithPayments: ["fincimex"],
    },
    {
        id: "banksTerms",
        label: _("Terms and Conditions of BPA, BANDEC, BANMET"),
    },
];

export const fieldsSyncStep = [
    {
        id: "syncTransfermovilEmail",
        label: _("Business Email"),
    },
    {
        id: "syncTransfermovilPassword",
        label: _("Password"),
        type: "password",
    },
    {
        id: "syncEnzonaEmailOrPhone",
        label: _("Email or Phone (8 digits)"),
    },
    {
        id: "syncEnzonaPassword",
        label: _("Password"),
        type: "password",
    },
];
