import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { fieldsThirdStep } from "consts/sign-up/fieldsConfig";
import { isFieldVisible } from "hooks/sign-up/formUtils";
import { optionsMap as staticOptionsMap } from "consts/sign-up/optionsConfig";
import CustomTextField from "components/MDInput/CustomTextField";
import _ from "i18n/translate";

function ThirdStep({ formik }) {
    const conditions = useMemo(
        () => [
            (field, formik) => {
                if (["personalEmail", "homePhone", "birthDate", "gender"].includes(field.id)) {
                    return formik.values.userType === "individual";
                }
                if (["businessCodeType", "businessCode"].includes(field.id)) {
                    return formik.values.userType === "legalEntity";
                }
                return true;
            },
        ],
        [formik.values.userType]
    );

    const provinces = useMemo(
        () => [
            { id: 1, name: _("Havana P") },
            { id: 2, name: _("Matanzas P") },
        ],
        []
    );
    const municipalities = useMemo(
        () => [
            { id: 1, name: _("Havana M"), provinceId: 1 },
            { id: 2, name: _("Matanzas M"), provinceId: 2 },
        ],
        []
    );

    const optionsMap = useMemo(
        () => ({
            ...staticOptionsMap,
            province: provinces,
            municipality: municipalities,
        }),
        [provinces, municipalities]
    );

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: 2 }}>
            {fieldsThirdStep
                .filter((field) => isFieldVisible(field, formik, conditions))
                .map((field) => {
                    return (
                        <React.Fragment key={field.id}>
                            {(field.id.includes("contractId") ||
                                field.id.includes("certifiedCopyOf")) && (
                                <Typography
                                    sx={{
                                        width: "100%",
                                        textTransform: "capitalize",
                                        fontSize: "1rem",
                                        mb: -2,
                                        mt: 1,
                                    }}
                                >
                                    {field.id.includes("contractId") ? "Transfermovil" : "Fincimex"}
                                </Typography>
                            )}
                            <CustomTextField
                                id={field.id}
                                label={field.label}
                                formik={formik}
                                width={field.width}
                                select={field.select}
                                options={optionsMap[field.id]}
                                type={field.type}
                            />
                        </React.Fragment>
                    );
                })}
        </Box>
    );
}

ThirdStep.propTypes = {
    formik: PropTypes.shape({
        values: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        touched: PropTypes.object.isRequired,
        handleChange: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired,
        setFieldValue: PropTypes.func.isRequired,
    }).isRequired,
};

export default ThirdStep;
