import _ from "i18n/translate";
import React from "react";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Card from "@mui/material/Card";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useSnackbar } from "notistack";
import { Typography } from "@mui/material";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import CustomTextField from "components/MDInput/CustomTextField";

function VerifyEmail() {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            verifyCode: "",
        },
        validationSchema: Yup.object({
            verifyCode: Yup.string().required(_("The verification code is required")),
        }),
        onSubmit: async (values) => {
            const token = await executeRecaptcha("form_submission");

            if (token) {
                try {
                    enqueueSnackbar(_("Verify the email when the endpoint is set up"), {
                        variant: "error",
                    });
                    navigate("/authentication/sign-in");
                } catch (error) {
                    enqueueSnackbar(_("Error during authentication"), { variant: "error" });
                }
            } else {
                enqueueSnackbar(_("Please reload the page. Sorry for the inconvenience"), {
                    variant: "error",
                });
            }
        },
    });

    return (
        <CoverLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        {_("Sign Up")}
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        {_("Verify Email")}
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <Typography sx={{ fontSize: ".8rem", fontWeight: 100 }}>
                        {_(
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget."
                        )}
                    </Typography>
                    <Formik
                        initialValues={formik.initialValues}
                        validationSchema={formik.validationSchema}
                        onSubmit={formik.handleSubmit}
                    >
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <MDBox mt={2} mb={2}>
                                    <CustomTextField
                                        key={"verifyCode"}
                                        id={"verifyCode"}
                                        label={_("Verification Code")}
                                        formik={formik}
                                    />
                                </MDBox>
                                <MDBox mb={4}>
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        fullWidth
                                        type="submit"
                                    >
                                        {_("Log In")}
                                    </MDButton>
                                </MDBox>
                            </form>
                        )}
                    </Formik>
                    <Typography sx={{ fontSize: ".8rem", fontWeight: 100 }}>
                        {_(
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor."
                        )}
                    </Typography>
                </MDBox>
            </Card>
        </CoverLayout>
    );
}

export default VerifyEmail;
