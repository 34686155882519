/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import transfermovilImage from "assets/images/logos/transfermovil.jpg";
import enzonaImage from "assets/images/logos/enzona.jpg";
import fincimexImage from "assets/images/logos/fincimex.jpg";
import FirstStep from "./steps/FirstStep";
import SecondStep from "./steps/SecondStep";
import ThirdStep from "./steps/ThirdStep";
import FourthStep from "./steps/FourthStep";
import FifthStep from "./steps/FifthStep";
import SyncStep from "./steps/SyncStep";
import useRegistrationFormik from "../../../hooks/sign-up/useRegistrationFormik";
import { Formik } from "formik";
import { stepMessages } from "../../../consts/sign-up/fieldsConfig";
import StepperPayment from "./StepperPayment";
import _ from "i18n/translate";

function Cover() {
    const [step, setStep] = useState(1);
    const [sync, setSync] = useState(false);

    const paymentMethods = [
        { id: "transfermovil", label: _("Transfermovil"), img: transfermovilImage },
        { id: "enzona", label: _("Enzona"), img: enzonaImage },
        { id: "fincimex", label: _("Fincimex"), img: fincimexImage },
    ];

    const formik = useRegistrationFormik(step, setStep, paymentMethods, sync);

    const handleBack = () => {
        if (sync && step === 2) setSync(false);
        setStep((prevStep) => (sync && step !== 2 ? 2 : Math.max(prevStep - 1, 1)));
    };

    return (
        <CoverLayout image={bgImage}>
            <Card sx={{ mt: -4, mb: 5, boxShadow: 8 }}>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={3}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        {step === 2 && sync ? _("Synchronize") : _("Join us today")}
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        {step === 2 && sync
                            ? _("Credentials to synchronize data")
                            : stepMessages[step]}
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    {step >= 2 && step <= 5 && !sync ? (
                        <StepperPayment
                            formik={formik}
                            step={step}
                            paymentMethods={paymentMethods}
                        />
                    ) : null}

                    <Formik
                        initialValues={formik.initialValues}
                        validationSchema={formik.validationSchema}
                        onSubmit={formik.handleSubmit}
                    >
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                {step === 1 && (
                                    <FirstStep
                                        formik={formik}
                                        paymentMethods={paymentMethods}
                                        setSync={setSync}
                                    />
                                )}
                                {step === 2 && sync && <SyncStep formik={formik} />}
                                {step === 2 && !sync && <SecondStep formik={formik} />}
                                {step === 3 && <ThirdStep formik={formik} />}
                                {step === 4 && <FourthStep formik={formik} />}
                                {step === 5 && (
                                    <FifthStep formik={formik} paymentMethods={paymentMethods} />
                                )}

                                <MDBox mt={4} mb={1} display="flex" justifyContent="space-between">
                                    {step > 1 && (
                                        <MDButton
                                            variant="gradient"
                                            color="info"
                                            fullWidth
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                        >
                                            {_("Back")}
                                        </MDButton>
                                    )}
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        type="submit"
                                        fullWidth
                                        sx={{ ml: 1 }}
                                        onClick={() => {
                                            if (step === 1) setSync(false);
                                        }}
                                    >
                                        {step === 5 ? _("Finish Registration") : _("Next")}
                                    </MDButton>
                                </MDBox>
                            </form>
                        )}
                    </Formik>

                    <MDBox mt={3} mb={1} textAlign="center">
                        <MDTypography variant="button" color="text">
                            {_("Do you already have an account? ")}
                            <MDTypography
                                component={Link}
                                to="/authentication/sign-in"
                                variant="button"
                                color="info"
                                fontWeight="medium"
                                textGradient
                            >
                                {_("Log in")}
                            </MDTypography>
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </Card>
        </CoverLayout>
    );
}

export default Cover;
