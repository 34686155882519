import React from "react";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel, Typography, Box } from "@mui/material";
import MDBox from "components/MDBox";

function CustomCheckbox({ checked, onChange, id, label, error }) {
    return (
        <MDBox mb={1}>
            <FormControlLabel
                control={<Checkbox checked={checked} onChange={onChange} id={id} name={id} />}
                sx={{
                    m: 0,
                    "& .MuiTypography-root": {
                        fontSize: ".8rem",
                    },
                    "&.MuiFormControlLabel-root": {
                        display: "flex",
                        flexWrap: "nowrap",
                    },
                }}
                label={
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "nowrap",
                        }}
                    >
                        <Typography>{label}</Typography>
                    </Box>
                }
            />
            {error && (
                <Typography color="error" sx={{ fontSize: ".8rem", mt: -1 }}>
                    {String(error)}
                </Typography>
            )}
        </MDBox>
    );
}

CustomCheckbox.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    error: PropTypes.any,
};

export default CustomCheckbox;
