import React from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import CustomTextField from "components/MDInput/CustomTextField";
import { fieldsFirstStep } from "consts/sign-up/fieldsConfig";
import { usePasswordVisibility } from "hooks/sign-up/formUtils";
import _ from "i18n/translate";

function FirstStep({ formik, paymentMethods, setSync }) {
    const { showPasswords, toggleShowPassword } = usePasswordVisibility([
        "password",
        "confirmPassword",
    ]);

    const handlePaymentSelection = (paymentMethod) => {
        const currentSelection = Array.isArray(formik.values.selectedPayment)
            ? formik.values.selectedPayment
            : [];

        formik.setFieldValue(
            "selectedPayment",
            currentSelection.includes(paymentMethod)
                ? currentSelection.filter((method) => method !== paymentMethod)
                : [...currentSelection, paymentMethod]
        );
    };

    return (
        <>
            {fieldsFirstStep.map((field) => (
                <CustomTextField
                    key={field.id}
                    id={field.id}
                    label={field.label}
                    formik={formik}
                    type={field.type}
                    showPassword={field.type === "password" && showPasswords[field.id]}
                    toggleShowPassword={
                        field.type === "password" ? () => toggleShowPassword(field.id) : null
                    }
                    adornment={field.type === "password"}
                />
            ))}

            <MDBox mt={3} display="flex" justifyContent="space-around">
                {paymentMethods
                    .filter((method) => method.id !== "enzona")
                    .map((method) => (
                        <MDBox
                            component="img"
                            key={method.id}
                            src={method.img}
                            alt={method.label}
                            sx={{
                                width: 50,
                                height: 50,
                                p: 1,
                                border: formik.values.selectedPayment.includes(method.id)
                                    ? "1px solid #2A84EB"
                                    : null,
                                borderRadius: 2,
                                boxShadow: 1,
                                cursor: "pointer",
                            }}
                            onClick={() => handlePaymentSelection(method.id)}
                        />
                    ))}
            </MDBox>

            {formik.errors.selectedPayment && (
                <MDBox mt={2}>
                    <Typography color="error" sx={{ textAlign: "center", fontSize: ".8rem" }}>
                        {formik.errors.selectedPayment}
                    </Typography>
                </MDBox>
            )}

            <MDBox mt={3} mb={1} textAlign="center">
                <Typography color="text" sx={{ fontSize: ".8rem", color: "gray", fontWeight: 100 }}>
                    {_("Do you already have an account with the selected service(s)?")}{" "}
                    <Button
                        type="submit"
                        fontWeight="medium"
                        onClick={() => {
                            if (Object.keys(formik.errors).length === 0) setSync(true);
                        }}
                        sx={{
                            color: "#318AEC",
                            textTransform: "capitalize",
                            m: 0,
                            p: 0,
                            fontSize: ".9rem",
                        }}
                    >
                        {_("Synchronize")}
                    </Button>
                </Typography>
            </MDBox>
        </>
    );
}

FirstStep.propTypes = {
    formik: PropTypes.shape({
        values: PropTypes.object.isRequired,
        handleChange: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired,
        touched: PropTypes.object,
        errors: PropTypes.object,
        setFieldValue: PropTypes.func.isRequired,
    }).isRequired,
    paymentMethods: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            img: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    setSync: PropTypes.func.isRequired,
};

export default FirstStep;
