import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import CustomTextField from "components/MDInput/CustomTextField";
import { fieldsSecondStep } from "consts/sign-up/fieldsConfig";
import { isFieldVisible, usePasswordVisibility } from "hooks/sign-up/formUtils";
import { optionsMap } from "consts/sign-up/optionsConfig";

function SecondStep({ formik }) {
    const conditions = useMemo(
        () => [
            (field, formik) => {
                if (field.id === "NIT" && !formik.values.selectedPayment.includes("fincimex")) {
                    return formik.values.userType === "legalEntity";
                }
                return true;
            },
        ],
        [formik.values.selectedPayment, formik.values.userType]
    );

    const { showPasswords, toggleShowPassword } = usePasswordVisibility([
        "transfermovilPassword",
        "confirmTransfermovilPassword",
        "fincimexPassword",
        "confirmFincimexPassword",
    ]);

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2, flexWrap: "wrap" }}>
            {fieldsSecondStep
                .filter((field) => isFieldVisible(field, formik, conditions))
                .map((field, index) => (
                    <React.Fragment key={index}>
                        {field.label === "Password" && (
                            <Typography
                                sx={{
                                    width: "100%",
                                    textTransform: "capitalize",
                                    fontSize: "1rem",
                                    mb: -2,
                                    mt: 1,
                                }}
                            >
                                {field.id.includes("transfermovil") ? "Transfermovil" : "Fincimex"}
                            </Typography>
                        )}
                        <CustomTextField
                            id={field.id}
                            label={field.label}
                            formik={formik}
                            width={field.width}
                            select={field.select}
                            options={optionsMap[field.id]}
                            type={field.type}
                            showPassword={field.type === "password" && showPasswords[field.id]}
                            toggleShowPassword={
                                field.type === "password"
                                    ? () => toggleShowPassword(field.id)
                                    : null
                            }
                            adornment={field.type === "password"}
                        />
                    </React.Fragment>
                ))}
        </Box>
    );
}

SecondStep.propTypes = {
    formik: PropTypes.shape({
        values: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        touched: PropTypes.object.isRequired,
        handleChange: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired,
    }).isRequired,
};

export default SecondStep;
