import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import MDBox from "components/MDBox";
import { paymentPlans } from "consts/sign-up/optionsConfig";
import CustomTextField from "components/MDInput/CustomTextField";
import _ from "i18n/translate";

const FifthStep = ({ formik, paymentMethods }) => {
    return (
        <>
            <CustomTextField
                key={"paymentPlan"}
                id={"paymentPlan"}
                label={_("Select a Payment Plan")}
                formik={formik}
                select={true}
                options={paymentPlans}
            />

            <MDBox mt={3} display="flex" justifyContent="space-around">
                {paymentMethods
                    .filter((method) => method.id != "fincimex")
                    .map((method) => (
                        <MDBox
                            component="img"
                            key={method.id}
                            src={method.img}
                            alt={method.label}
                            sx={{
                                width: 50,
                                height: 50,
                                p: 1,
                                border:
                                    formik.values.paymentMethod === method.id
                                        ? "1px solid #2A84EB"
                                        : null,
                                borderRadius: 2,
                                boxShadow: 1,
                                cursor: "pointer",
                            }}
                            onClick={() => formik.setFieldValue("paymentMethod", method.id)}
                        />
                    ))}
            </MDBox>

            {formik.errors.paymentMethod && (
                <MDBox mt={2} display="flex" justifyContent="center" width="100%">
                    <Typography color="error" sx={{ textAlign: "center", fontSize: ".8rem" }}>
                        {formik.errors.paymentMethod}
                    </Typography>
                </MDBox>
            )}
        </>
    );
};

FifthStep.propTypes = {
    formik: PropTypes.shape({
        values: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        touched: PropTypes.object.isRequired,
        handleChange: PropTypes.func.isRequired,
        setFieldValue: PropTypes.func.isRequired,
    }).isRequired,
    paymentMethods: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            img: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default FifthStep;
