import React from "react";
import PropTypes from "prop-types";
import { Modal, Box, Typography, Button, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import MDBox from "components/MDBox";
import _ from "i18n/translate";

const ForgotPasswordModal = ({ open, onClose }) => {
    const { enqueueSnackbar } = useSnackbar();

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email(_("Must be a valid email")).required(_("Email is required")),
        }),
        onSubmit: async (values) => {
            try {
                enqueueSnackbar(_("Reset password with Keycloak"), { variant: "error" });
            } catch (error) {
                enqueueSnackbar(_("Error during password reset"), {
                    variant: "error",
                });
            }
        },
    });

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    width: { xs: "90%", md: 400 },
                    m: "auto",
                    mt: "20%",
                    p: 3,
                    borderRadius: 2,
                    border: "none",
                    bgcolor: "#fff",
                }}
            >
                <Typography variant="h6">{_("Reset Password")}</Typography>
                <Formik
                    initialValues={formik.initialValues}
                    validationSchema={formik.validationSchema}
                    onSubmit={formik.handleSubmit}
                >
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <MDBox my={3}>
                                <TextField
                                    type="email"
                                    label={_("Email")}
                                    fullWidth
                                    variant="standard"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    helperText={formik.touched.email && formik.errors.email}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    sx={{
                                        "& .MuiFormHelperText-root": {
                                            color: "red",
                                        },
                                    }}
                                />
                            </MDBox>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: 2,
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={onClose}
                                    sx={{
                                        color: "#348DED",
                                        "&:hover": { border: "1px solid #348DED" },
                                    }}
                                >
                                    {_("Close")}
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={formik.isSubmitting}
                                    sx={{ bgcolor: "info", color: "#fff", ml: 2 }}
                                >
                                    {formik.isSubmitting ? _("Sending...") : _("Send")}
                                </Button>
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
};

ForgotPasswordModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ForgotPasswordModal;
