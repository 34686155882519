import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Box } from "@mui/material";
import CustomTextField from "components/MDInput/CustomTextField";
import { isFieldVisible } from "hooks/sign-up/formUtils";
import { fieldsFourthStep1, fieldsFourthStep2, termsFourthStep } from "consts/sign-up/fieldsConfig";
import CustomCheckbox from "components/MDInput/CustomCheckbox";
import _ from "i18n/translate";
import { optionsMap } from "consts/sign-up/optionsConfig";

function FourthStep({ formik }) {
    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", gap: 2 }}>
            {fieldsFourthStep1
                .filter((field) => isFieldVisible(field, formik, []))
                .map((field) => (
                    <CustomTextField
                        key={field.id}
                        id={field.id}
                        label={field.label}
                        formik={formik}
                        width={field.width}
                        select={field.select}
                        options={optionsMap[field.id]}
                        type={field.type}
                    />
                ))}

            {formik.values.selectedPayment.some((payment) => payment.includes("fincimex")) && (
                <CustomCheckbox
                    checked={Boolean(formik.values["isAdditionalBankInfoChecked"])}
                    onChange={(event) => {
                        const { checked } = event.target;
                        formik.setFieldValue("isAdditionalBankInfoChecked", checked);
                        if (!checked) {
                            fieldsFourthStep2.forEach((field) => {
                                formik.setFieldValue(field.id, "");
                                formik.setFieldTouched(field.id, false);
                            });
                        }
                    }}
                    id={"isAdditionalBankInfoChecked"}
                    label={_("Include another bank account")}
                />
            )}

            {formik.values.isAdditionalBankInfoChecked &&
                fieldsFourthStep2
                    .filter((field) => isFieldVisible(field, formik, []))
                    .map((field) => (
                        <CustomTextField
                            key={field.id}
                            id={field.id}
                            label={field.label}
                            formik={formik}
                            width={field.width}
                            select={field.select}
                            options={optionsMap[field.id]}
                            type={field.type}
                        />
                    ))}

            <FormGroup sx={{ mt: 2 }}>
                {termsFourthStep
                    .filter((field) => isFieldVisible(field, formik, []))
                    .map((term) => (
                        <CustomCheckbox
                            key={term.id}
                            checked={Boolean(formik.values[term.id])}
                            onChange={formik.handleChange}
                            id={term.id}
                            label={`${_("Accept")} ${term.label}`}
                            error={
                                formik.touched[term.id] && formik.errors[term.id]
                                    ? formik.errors[term.id]
                                    : ""
                            }
                        />
                    ))}
            </FormGroup>
        </Box>
    );
}

FourthStep.propTypes = {
    formik: PropTypes.shape({
        values: PropTypes.object.isRequired,
        errors: PropTypes.string.isRequired,
        touched: PropTypes.string.isRequired,
        handleChange: PropTypes.func.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        setFieldTouched: PropTypes.func.isRequired,
    }).isRequired,
};

export default FourthStep;
