/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useSnackbar } from "notistack";
import ForgotPasswordModal from "components/MDModal/ForgotPasswordModal";
import { Link } from "react-router-dom";
import CustomTextField from "components/MDInput/CustomTextField";
import { usePasswordVisibility } from "hooks/sign-up/formUtils";
import _ from "i18n/translate";

function Basic() {
    const [rememberMe, setRememberMe] = useState(false);
    const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { enqueueSnackbar } = useSnackbar();

    const { showPasswords, toggleShowPassword } = usePasswordVisibility(["password"]);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email(_("Must be a valid email")).required(_("Email is required")),
            password: Yup.string()
                .min(6, _("Password must be at least 6 characters"))
                .matches(/[A-Z]/, _("Password must contain at least one uppercase letter"))
                .matches(/[a-z]/, _("Password must contain at least one lowercase letter"))
                .matches(/[0-9]/, _("Password must contain at least one number"))
                .matches(/[\W_]/, _("Password must contain at least one special character"))
                .required(_("Password is required")),
        }),
        onSubmit: async (values) => {
            const token = await executeRecaptcha("form_submission");

            if (token) {
                try {
                    enqueueSnackbar(_("Login with Keycloak"), { variant: "error" });
                } catch (error) {
                    enqueueSnackbar(_("Error during authentication"), { variant: "error" });
                }
            } else {
                enqueueSnackbar(_("Please refresh the page. Sorry for the inconvenience"), {
                    variant: "error",
                });
            }
        },
    });

    const handleSocialLogin = (provider) => {
        enqueueSnackbar(_("Login with Keycloak") + " " + provider, { variant: "error" });
    };

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        {_("Log In")}
                    </MDTypography>
                    <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                        <Grid item xs={2}>
                            <MDTypography
                                component={MuiLink}
                                href="#"
                                onClick={() => handleSocialLogin("facebook")}
                                variant="body1"
                                color="white"
                                sx={{ cursor: "pointer" }}
                            >
                                <FacebookIcon color="inherit" />
                            </MDTypography>
                        </Grid>
                        <Grid item xs={2}>
                            <MDTypography
                                component={MuiLink}
                                href="#"
                                onClick={() => handleSocialLogin("github")}
                                variant="body1"
                                color="white"
                                sx={{ cursor: "pointer" }}
                            >
                                <GitHubIcon color="inherit" />
                            </MDTypography>
                        </Grid>
                        <Grid item xs={2}>
                            <MDTypography
                                component={MuiLink}
                                href="#"
                                onClick={() => handleSocialLogin("google")}
                                variant="body1"
                                color="white"
                                sx={{ cursor: "pointer" }}
                            >
                                <GoogleIcon color="inherit" />
                            </MDTypography>
                        </Grid>
                    </Grid>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <Formik
                        initialValues={formik.initialValues}
                        validationSchema={formik.validationSchema}
                        onSubmit={formik.handleSubmit}
                    >
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <MDBox mb={3}>
                                    <CustomTextField
                                        key={"email"}
                                        id={"email"}
                                        label={_("Email")}
                                        formik={formik}
                                        type={"email"}
                                    />
                                </MDBox>
                                <MDBox mb={2}>
                                    <CustomTextField
                                        key={"password"}
                                        id={"password"}
                                        label={_("Password")}
                                        formik={formik}
                                        type={"password"}
                                        showPassword={showPasswords["password"]}
                                        toggleShowPassword={() => toggleShowPassword("password")}
                                        adornment={true}
                                    />
                                </MDBox>
                                <MDBox mt={-2} textAlign="right">
                                    <MuiLink
                                        onClick={() => setOpenForgotPasswordModal(true)}
                                        color="info"
                                        sx={{
                                            fontSize: ".75rem",
                                            color: "#348DED",
                                            cursor: "pointer",
                                        }}
                                    >
                                        {_("Forgot your password?")}
                                    </MuiLink>
                                </MDBox>
                                <MDBox mt={-1}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={rememberMe}
                                                onChange={() => setRememberMe(!rememberMe)}
                                                name="rememberMe"
                                                sx={{
                                                    "& .MuiSwitch-switchBase.Mui-checked": {
                                                        color: "#348DED !important",
                                                    },
                                                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                                        {
                                                            backgroundColor: "#348DED !important",
                                                            borderColor: "#348DED !important",
                                                        },
                                                    "& .MuiSwitch-switchBase.Mui-checked > .MuiSwitch-thumb":
                                                        {
                                                            backgroundColor: "#348DED !important",
                                                            borderColor: "#348DED !important",
                                                            boxShadow: 3,
                                                        },
                                                }}
                                            />
                                        }
                                        label={_("Remember Me")}
                                        sx={{
                                            "& .MuiFormControlLabel-label": {
                                                fontSize: "0.8rem !important",
                                                fontWeight: 100,
                                            },
                                        }}
                                    />
                                </MDBox>
                                <MDBox mt={4} mb={1}>
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        fullWidth
                                        type="submit"
                                    >
                                        {_("Log In")}
                                    </MDButton>
                                </MDBox>
                            </form>
                        )}
                    </Formik>
                    <MDBox mt={3} mb={1} textAlign="center">
                        <MDTypography variant="button" color="text">
                            {_("Don't have an account?")}{" "}
                            <MDTypography
                                component={Link}
                                to="/authentication/sign-up"
                                variant="button"
                                color="info"
                                fontWeight="medium"
                                textGradient
                            >
                                {_("Sign Up")}
                            </MDTypography>
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </Card>
            <ForgotPasswordModal
                open={openForgotPasswordModal}
                onClose={() => setOpenForgotPasswordModal(false)}
            />
        </BasicLayout>
    );
}

export default Basic;
