import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import _ from "i18n/translate";

const useRegistrationFormik = (step, setStep, paymentMethods, sync) => {
    const [validate, setValidate] = useState(false);
    const navigate = useNavigate();

    const validationSchemas = {
        1: Yup.object({
            email: Yup.string()
                .email(_("Must be a valid email"))
                .matches(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    _("Email must follow the correct format")
                )
                .required(_("Email is required")),
            password: Yup.string()
                .min(6, _("Password must be at least 6 characters long"))
                .matches(/[A-Z]/, _("Must contain an uppercase letter"))
                .matches(/[a-z]/, _("Must contain a lowercase letter"))
                .matches(/[0-9]/, _("Must contain a number"))
                .matches(/[\W_]/, _("Must contain a special character"))
                .required(_("Password is required")),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("password"), null], _("Passwords must match"))
                .required(_("Confirming password is required")),
            selectedPayment: Yup.array()
                .of(Yup.string())
                .min(1, _("Select at least one payment method")),
        }),
        2: Yup.object({
            fullName: Yup.string()
                .required(_("Full name is required"))
                .test(
                    "full-name",
                    _("Full name must consist of at least one first name and two last names"),
                    (value) => value && value.trim().split(/\s+/).length >= 3
                ),
            position: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("fincimex"),
                then: () => Yup.string().required(_("Position is required")),
                otherwise: () => Yup.string().notRequired(),
            }),

            phoneNumber: Yup.string()
                .required(_("Phone number is required"))
                .matches(/^(\+\d{1,3})?\s?\d{7,14}$/, _("Phone number is not valid")),
            userType: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("transfermovil"),
                then: () => Yup.string().required(_("User type is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            idCard: Yup.string()
                .length(11, _("ID card must have 11 digits"))
                .matches(/^\d+$/, _("ID card can only contain numbers"))
                .required(_("ID card is required")),
            NIT: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("transfermovil"),
                then: () =>
                    Yup.string().when("userType", {
                        is: (userType) => userType?.includes("legalEntity"),
                        then: () =>
                            Yup.string()
                                .matches(/^\d+$/, _("Can only contain numbers"))
                                .required(_("Tax Identification Number (NIT) is required")),
                        otherwise: () => Yup.string().notRequired(),
                    }),
                otherwise: () =>
                    Yup.string()
                        .matches(/^\d+$/, _("Can only contain numbers"))
                        .required(_("Tax Identification Number (NIT) is required")),
            }),
            businessEmail: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("transfermovil"),
                then: () =>
                    Yup.string()
                        .email(_("Must be a valid email"))
                        .matches(
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(cu)$/,
                            _("Email must be national")
                        )
                        .required(_("Business email is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            contractEmail: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("fincimex"),
                then: () =>
                    Yup.string()
                        .email(_("Must be a valid email"))
                        .matches(
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            _("Email must follow the correct format")
                        )
                        .required(_("Email is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            officePhone: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("transfermovil"),
                then: () =>
                    Yup.string()
                        .required(_("Office phone is required"))
                        .matches(/^(\+\d{1,3})?\s?\d{7,14}$/, _("Phone number is not valid")),
                otherwise: () => Yup.string().notRequired(),
            }),
        }).concat(
            Yup.object().shape(
                paymentMethods.reduce((acc, method) => {
                    return {
                        ...acc,
                        [`${method.id}Password`]: Yup.string().when("selectedPayment", {
                            is: (val) => val.includes(method.id),
                            then: () =>
                                Yup.string()
                                    .min(6, _("Password must be at least 6 characters long"))
                                    .matches(/[A-Z]/, _("Must contain an uppercase letter"))
                                    .matches(/[a-z]/, _("Must contain a lowercase letter"))
                                    .matches(/[0-9]/, _("Must contain a number"))
                                    .matches(/[\W_]/, _("Must contain a special character"))
                                    .required(_("Password is required")),
                        }),
                        [`confirm${
                            method.id.charAt(0).toUpperCase() + method.id.slice(1)
                        }Password`]: Yup.string().when(`${method.id}Password`, {
                            is: (val) => val && val.length > 0,
                            then: () =>
                                Yup.string()
                                    .oneOf(
                                        [Yup.ref(`${method.id}Password`), null],
                                        _("Passwords must match")
                                    )
                                    .required(_("Confirming password is required")),
                            otherwise: () => Yup.string(),
                        }),
                    };
                }, {})
            )
        ),
        3: Yup.object({
            personalEmail: Yup.string().when(["userType", "selectedPayment"], {
                is: (userType, selectedPayment) =>
                    userType?.includes("individual") && selectedPayment?.includes("transfermovil"),
                then: () =>
                    Yup.string()
                        .email(_("Must be a valid email"))
                        .matches(
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            _("Email must follow the correct format")
                        )
                        .required(_("Personal email is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            homePhone: Yup.string().when(["userType", "selectedPayment"], {
                is: (userType, selectedPayment) =>
                    userType?.includes("individual") && selectedPayment?.includes("transfermovil"),
                then: () =>
                    Yup.string()
                        .matches(/^(\+\d{1,3})?\s?\d{7,14}$/, _("Phone number is not valid"))
                        .required(_("Home phone number is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            birthDate: Yup.date().when(["userType", "selectedPayment"], {
                is: (userType, selectedPayment) =>
                    userType?.includes("individual") && selectedPayment?.includes("transfermovil"),
                then: () =>
                    Yup.string()
                        .max(new Date(), _("Birth date cannot be in the future"))
                        .required(_("Birth date is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            gender: Yup.string().when(["userType", "selectedPayment"], {
                is: (userType, selectedPayment) =>
                    userType?.includes("individual") && selectedPayment?.includes("transfermovil"),
                then: () => Yup.string().required(_("Gender is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            businessName: Yup.string().required(_("Business name is required")),
            businessCodeType: Yup.string().when(["userType", "selectedPayment"], {
                is: (userType, selectedPayment) =>
                    userType?.includes("legalEntity") && selectedPayment?.includes("transfermovil"),
                then: () => Yup.string().required(_("Business code type is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            businessCode: Yup.string().when(["userType", "selectedPayment"], {
                is: (userType, selectedPayment) =>
                    userType?.includes("legalEntity") && selectedPayment?.includes("transfermovil"),
                then: () => Yup.string().required(_("Business code is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            nationality: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment?.includes("fincimex"),
                then: () => Yup.string().required(_("Nationality is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            documentType: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment?.includes("fincimex"),
                then: () => Yup.string().required(_("Document type is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            documentNumber: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment?.includes("fincimex"),
                then: () =>
                    Yup.string()
                        .required(_("Document number is required"))
                        .matches(/^\d+$/, _("Document number must only contain numbers")),
                otherwise: () => Yup.string().notRequired(),
            }),
            documentDate: Yup.date().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment?.includes("fincimex"),
                then: () => Yup.date().required(_("Document date is required")),
                otherwise: () => Yup.date().notRequired(),
            }),
            issuingPerson: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment?.includes("fincimex"),
                then: () => Yup.string().required(_("Issuing person is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            registryNumber: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment?.includes("fincimex"),
                then: () =>
                    Yup.string()
                        .matches(/^\d+$/, _("Registry number must only contain numbers"))
                        .required(_("Registry number is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            folio: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment?.includes("fincimex"),
                then: () =>
                    Yup.string()
                        .matches(/^\d+$/, _("Folio must only contain numbers"))
                        .required(_("Folio is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            page: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment?.includes("fincimex"),
                then: () =>
                    Yup.string()
                        .matches(/^\d+$/, _("Page must only contain numbers"))
                        .required(_("Page is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            section: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment?.includes("fincimex"),
                then: () => Yup.string().required(_("Section is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            inscription: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment?.includes("fincimex"),
                then: () => Yup.string().required(_("Inscription is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            legalAddress: Yup.string().required(_("Legal address is required")),
            province: Yup.string().required(_("Province is required")),
            municipality: Yup.string().required(_("Municipality is required")),
            city: Yup.string().required(_("City is required")),
            postalCode: Yup.string()
                .required(_("Postal code is required"))
                .matches(/^(\+\d{1,3})?\s?\d{3,10}$/, _("Postal code is not valid")),

            fax: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("fincimex"),
                then: () => Yup.string().required(_("Fax is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            ONECode: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("fincimex"),
                then: () => Yup.string().required(_("ONE code of the entity is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            contractId: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("transfermovil"),
                then: () => Yup.string().required(_("Contract ID is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            businessType: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("transfermovil"),
                then: () => Yup.string().required(_("Business type is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            createdAt: Yup.date().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("transfermovil"),
                then: () => Yup.string().required(_("Creation date is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            certifiedCopyOf: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("fincimex"),
                then: () => Yup.string().required(_("Certified copy of is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            issuedBy: Yup.string().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("fincimex"),
                then: () => Yup.string().required(_("Issued by field is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            createdIn: Yup.date().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("fincimex"),
                then: () => Yup.date().required(_("Created in date is required")),
                otherwise: () => Yup.date().notRequired(),
            }),
        }),
        4: Yup.object({
            bank: Yup.string().required(_("Bank is required")),
            accountType: Yup.string().required(_("Account Type is required")),
            accountName: Yup.string().required(_("Account Name is required")),
            accountNumber: Yup.string()
                .matches(
                    /^(03|05|06|12)\d{14}$/,
                    _("Account number must start with 03, 05, 06, or 12 and have 16 digits")
                )
                .required(_("Account Number is required")),
            isAdditionalBankInfoChecked: Yup.boolean(),
            bank2: Yup.string().when(["selectedPayment", "isAdditionalBankInfoChecked"], {
                is: (selectedPayment, isChecked) =>
                    selectedPayment.includes("fincimex") && isChecked,
                then: () => Yup.string().required(_("Bank is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            accountType2: Yup.string().when(["selectedPayment", "isAdditionalBankInfoChecked"], {
                is: (selectedPayment, isChecked) =>
                    selectedPayment.includes("fincimex") && isChecked,
                then: () => Yup.string().required(_("Account Type / Currency is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            accountNumber2: Yup.string().when(["selectedPayment", "isAdditionalBankInfoChecked"], {
                is: (selectedPayment, isChecked) =>
                    selectedPayment.includes("fincimex") && isChecked,
                then: () =>
                    Yup.string()
                        .matches(
                            /^(03|05|06|12)\d{14}$/,
                            _("Account number must start with 03, 05, 06, or 12 and have 16 digits")
                        )
                        .required(_("Account Number is required")),
                otherwise: () => Yup.string().notRequired(),
            }),
            bankingLicenseNumber: Yup.string().when(
                ["selectedPayment", "isAdditionalBankInfoChecked"],
                {
                    is: (selectedPayment, isChecked) =>
                        selectedPayment.includes("fincimex") && isChecked,
                    then: () =>
                        Yup.string()
                            .matches(/^\d+$/, _("Banking License Number must be numeric only"))
                            .required(_("Banking License Number is required")),
                    otherwise: () => Yup.string().notRequired(),
                }
            ),
            bankingLicenseDate: Yup.date().when(
                ["selectedPayment", "isAdditionalBankInfoChecked"],
                {
                    is: (selectedPayment, isChecked) =>
                        selectedPayment.includes("fincimex") && isChecked,
                    then: () => Yup.date().required(_("Banking License Date is required")),
                    otherwise: () => Yup.date().notRequired(),
                }
            ),
            commercialRegistrationLicenseNumber: Yup.string().when(
                ["selectedPayment", "isAdditionalBankInfoChecked"],
                {
                    is: (selectedPayment, isChecked) =>
                        selectedPayment.includes("fincimex") && isChecked,
                    then: () =>
                        Yup.string()
                            .matches(/^\d+$/, _("Must be numeric only"))
                            .required(_("Commercial Registration License Number is required")),
                    otherwise: () => Yup.string().notRequired(),
                }
            ),
            commercialRegistrationDate: Yup.date().when(
                ["selectedPayment", "isAdditionalBankInfoChecked"],
                {
                    is: (selectedPayment, isChecked) =>
                        selectedPayment.includes("fincimex") && isChecked,
                    then: () => Yup.date().required(_("Commercial Registration Date is required")),
                    otherwise: () => Yup.date().notRequired(),
                }
            ),
            transfermovilTerms: Yup.boolean().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("transfermovil"),
                then: () =>
                    Yup.boolean().oneOf(
                        [true],
                        _("You must accept the terms and conditions of Transfermovil")
                    ),
                otherwise: () => Yup.boolean().notRequired(),
            }),
            fincimexTerms: Yup.boolean().when("selectedPayment", {
                is: (selectedPayment) => selectedPayment.includes("fincimex"),
                then: () =>
                    Yup.boolean().oneOf(
                        [true],
                        _("You must accept the terms and conditions of Fincimex")
                    ),
                otherwise: () => Yup.boolean().notRequired(),
            }),
            banksTerms: Yup.boolean().oneOf(
                [true],
                _("You must accept the terms and conditions of BPA")
            ),
        }),
        5: Yup.object({
            paymentPlan: Yup.string().required(_("Payment Plan is required")),
            paymentMethod: Yup.string()
                .oneOf(
                    paymentMethods.map((method) => method.id),
                    _("Invalid payment method")
                )
                .required(_("Payment Method is required")),
        }),
        6: Yup.object({
            syncTransfermovilEmail: Yup.string()
                .notRequired()
                .test(
                    "email-transfermovil",
                    _("The Transfermovil email must be a valid email."),
                    function (value) {
                        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
                        return (
                            syncEnzonaEmailOrPhone.value !== "" ||
                            (value !== "" && emailRegex.test(value))
                        );
                    }
                ),

            syncTransfermovilPassword: Yup.string()
                .notRequired()
                .test(
                    "required-if-provided",
                    _("The Transfermovil password is required if provided."),
                    function (value, context) {
                        const { syncTransfermovilEmail } = context.parent;
                        return !syncTransfermovilEmail || value;
                    }
                ),

            syncEnzonaEmailOrPhone: Yup.string()
                .notRequired()
                .test(
                    "email-or-phone",
                    _("The Enzona email or phone must be valid."),
                    function (value) {
                        const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
                        const phoneRegex = /^\d{8}$/;
                        return (
                            syncTransfermovilEmail.value !== "" ||
                            !syncEnzonaPassword ||
                            (value !== "" && (emailRegex.test(value) || phoneRegex.test(value)))
                        );
                    }
                ),

            syncEnzonaPassword: Yup.string()
                .notRequired()
                .test(
                    "required-if-provided",
                    _("The Enzona password is required if provided."),
                    function (value, context) {
                        const { syncEnzonaEmailOrPhone } = context.parent;
                        return !syncEnzonaEmailOrPhone || value;
                    }
                ),
        }).test("at-least-one", _("You must fill at least one set of fields"), function (value) {
            const {
                syncTransfermovilEmail,
                syncTransfermovilPassword,
                syncEnzonaEmailOrPhone,
                syncEnzonaPassword,
            } = value;

            const atLeastOne =
                (syncTransfermovilEmail && syncTransfermovilPassword) ||
                (syncEnzonaEmailOrPhone && syncEnzonaPassword);

            return (
                atLeastOne ||
                this.createError({ message: _("You must fill at least one set of fields.") })
            );
        }),
    };

    const formik = useFormik({
        initialValues: {
            // Step 1 fields
            email: "",
            password: "",
            confirmPassword: "",
            selectedPayment: [],
            // Step 2 fields
            fullName: "",
            position: "",
            phoneNumber: "",
            userType: "",
            idCard: "",
            NIT: "",
            businessEmail: "",
            contractEmail: "",
            officePhone: "",
            transfermovilPassword: "",
            confirmTransfermovilPassword: "",
            fincimexPassword: "",
            confirmFincimexPassword: "",
            // Step 3 fields
            personalEmail: "",
            homePhone: "",
            birthDate: "",
            gender: "",
            businessName: "",
            businessCodeType: "",
            businessCode: "",
            nationality: "",
            documentType: "",
            documentNumber: "",
            documentDate: "",
            issuingPerson: "",
            registryNumber: "",
            folio: "",
            page: "",
            section: "",
            inscription: "",
            legalAddress: "",
            province: "",
            municipality: "",
            city: "",
            postalCode: "",
            fax: "",
            ONECode: "",
            contractId: "",
            businessType: "",
            createdAt: "",
            certifiedCopyOf: "",
            issuedBy: "",
            createdIn: "",
            // Step 4 fields
            bank: "",
            accountType: "",
            accountName: "",
            accountNumber: "",
            isAdditionalBankInfoChecked: false,
            bank2: "",
            accountType2: "",
            accountNumber2: "",
            bankingLicenseNumber: "",
            bankingLicenseDate: "",
            commercialRegistrationLicenseNumber: "",
            commercialRegistrationDate: "",
            transfermovilTerms: false,
            enzonaTerms: false,
            fincimexTerms: false,
            banksTerms: false,
            // Step 5 fields
            paymentPlan: "",
            paymentMethod: "",
            // Step sync fields
            syncTransfermovilEmail: "",
            syncTransfermovilPassword: "",
            syncEnzonaEmailOrPhone: "",
            syncEnzonaPassword: "",
        },
        validationSchema: validationSchemas[sync && step === 2 ? 6 : step],
        validateOnChange: validate,
        validateOnBlur: validate,
        onSubmit: async (values) => {
            if (step === 5) {
                try {
                    navigate("/authentication/verify-email");
                } catch (error) {}
            } else {
                setStep((prevStep) => (sync && step === 2 ? 5 : prevStep + 1));
            }
        },
    });

    useEffect(() => {
        setValidate(Object.keys(formik.errors).length > 0);
    }, [formik.errors]);

    useEffect(() => {
        if (formik.values.contractEmail === "")
            formik.setFieldValue("contractEmail", formik.values.email);

        if (
            formik.values.businessEmail === "" &&
            formik.values.selectedPayment.includes("transfermovil")
        )
            formik.setFieldValue("businessEmail", formik.values.email);

        if (formik.values.personalEmail === "")
            formik.setFieldValue("personalEmail", formik.values.email);
    }, [formik.values.email]);

    return formik;
};

export default useRegistrationFormik;
