import { useState } from "react";

export const usePasswordVisibility = (fields) => {
    const initialState = fields.reduce((acc, field) => {
        acc[field] = false;
        return acc;
    }, {});

    const [showPasswords, setShowPasswords] = useState(initialState);

    const toggleShowPassword = (field) => {
        setShowPasswords((prev) => ({
            ...prev,
            [field]: !prev[field],
        }));
    };

    return { showPasswords, toggleShowPassword };
};

export const isFieldVisible = (field, formik, conditions = []) => {
    for (const condition of conditions) {
        if (!condition(field, formik)) {
            return false;
        }
    }

    return (
        !field.visibleWithPayments ||
        field.visibleWithPayments.some((payment) => formik.values.selectedPayment.includes(payment))
    );
};
