import _ from "i18n/translate";

export const userTypes = [
    { id: "legalEntity", name: _("Legal Entity") },
    { id: "individual", name: _("Individual") },
];

export const gender = [
    { id: "male", name: _("Male") },
    { id: "female", name: _("Female") },
];

export const businessTypes = [
    { id: "selfEmployedWorkerTcp", name: _("Self-Employed Worker (TCP)") },
    { id: "nonAgriculturalCooperativeCna", name: _("Non-Agricultural Cooperative (CNA)") },
];

export const businessCodeTypes = [
    { id: "reeup", name: _("REEUP") },
    { id: "reeane", name: _("REEANE") },
    { id: "reuco", name: _("REUCO") },
    { id: "commercialRegister", name: _("Commercial Register") },
    {
        id: "branchesAndAgentsOfForeignCompanies",
        name: _("Branches and Agents of Foreign Companies"),
    },
    { id: "travelAgencyRegistration", name: _("Travel Agency Registration") },
    { id: "associationsRegistration", name: _("Associations Registration") },
    {
        id: "banksAndNonBankingFinancialInstitutions",
        name: _("Banks and Non-Banking Financial Institutions"),
    },
];

export const documentTypes = [
    { id: "legal", name: _("Legal") },
    { id: "tax", name: _("Tax") },
    { id: "literature", name: _("Literature") },
];

export const banks = [
    { id: "bpa", name: _("BPA") },
    { id: "bandec", name: _("BANDEC") },
    { id: "banmet", name: _("BANMET") },
];

export const accountTypes = [
    { id: "cup", name: _("CUP") },
    { id: "mlc", name: _("MLC") },
    { id: "usd", name: _("USD") },
];

export const paymentPlans = [
    { id: 1, name: _("1 Month - 100.00 CUP") },
    { id: 2, name: _("6 Months - 200.00 CUP") },
    { id: 3, name: _("1 Year - 300.00 CUP") },
];

export const optionsMap = {
    userType: userTypes,
    businessType: businessTypes,
    businessCodeType: businessCodeTypes,
    documentType: documentTypes,
    gender: gender,
    bank: banks,
    accountType: accountTypes,
    bank2: banks,
    accountType2: accountTypes,
};
