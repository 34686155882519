import React from "react";
import { TextField, MenuItem, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PropTypes from "prop-types";
import _ from "i18n/translate";

const CustomTextField = ({
    id,
    label,
    formik,
    select = false,
    options = [],
    type = "text",
    showPassword = false,
    toggleShowPassword,
    width = "100%",
    adornment = false,
}) => {
    return (
        <TextField
            fullWidth
            variant="standard"
            id={id}
            name={id}
            label={label}
            value={formik.values[id]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type={showPassword ? "text" : type}
            select={select}
            helperText={formik.touched[id] && formik.errors[id]}
            InputProps={{
                endAdornment: adornment && (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleShowPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            InputLabelProps={{
                shrink: type === "date" ? true : undefined,
            }}
            sx={{
                width: { xs: "100%", sm: width },
                mt: 1,
                "& .MuiFormHelperText-root": {
                    color: "red",
                },
                "& .MuiSelect-select": {
                    height: "30px !important",
                    ml: 0,
                },
            }}
        >
            {select && (
                <MenuItem value="">
                    <em>{_("Select")}</em>
                </MenuItem>
            )}
            {options?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    );
};

CustomTextField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    formik: PropTypes.shape({
        values: PropTypes.object.isRequired,
        handleChange: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired,
        errors: PropTypes.object.isRequired,
        touched: PropTypes.object.isRequired,
    }).isRequired,
    select: PropTypes.bool,
    options: PropTypes.array,
    type: PropTypes.string,
    showPassword: PropTypes.bool,
    toggleShowPassword: PropTypes.func,
    width: PropTypes.string,
    adornment: PropTypes.bool,
};

export default CustomTextField;
